<template>
	<component
		:is="as"
		:class="[col ? `col-${col}` : 'col', sm && `col-sm-${sm}`]"
		v-bind="attrs"
		v-on="$listeners"
	>
		<slot />
	</component>
</template>

<script>
import { keys, omit } from "lodash";

export default {
	name: "ObiCol",
	props: {
		as: {
			type: [String, Object],
			default: "div",
		},
		col: {
			type: [String, Number],
			default: null,
		},
		sm: {
			type: [String, Number],
			default: null,
		},
		md: {
			type: [String, Number],
			default: null,
		},
		lg: {
			type: [String, Number],
			default: null,
		},
	},
	computed: {
		attrs() {
			return omit(this.$attrs, keys(this.$props));
		},
	},
};
</script>
<style lang="scss" scoped>
.obi-col {
}
</style>
